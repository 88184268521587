import Intro from "../components/intro/Intro"
import Portfolio from "../components/portfolio/Portfolio"
import Testimonial from "../components/testimonial/Testimonial"
import Contact from "../components/contact/Contact"
import WhyUs from "../components/whyus/WhyUs"
import AboutUs from "../components/aboutus/AboutUs"
import './Sections.scss'

export default function Sections() {
  return (
    <div className="sections">
      <Intro/>
        {/* <Portfolio/> */}
        {/* <Works/> */}
        <WhyUs/>
        <Testimonial/>
        <AboutUs/>
        <Contact/>
    </div>
  )
}
