import "./WhyUs.scss"

export default function WhyUs() {

    const data=[
        {
            "id" :1,
            "img" : "assets/whyus/why1.png",
            "title" : "Up-Gradation of Courses"
        },
        {
            "id" :2,
            "img" : "assets/whyus/why2.png",
            "title" : "100% Job Assistance"
        },
        {
            "id" :3,
            "img" : "assets/whyus/why3.png",
            "title" : "100% Practical Training"
        },
        {
            "id" :4,
            "img" : "assets/whyus/why4.png",
            "title" : "Small Batches for Personalised Attention"
        }
    ]

  return (
    <div className='whyus' id="whyus">
      <h1 className="head">
        Why Us
      </h1>
      <div className="content">
        {data.map((d) => (
            <div className="box">
                <img src={d.img} alt="Why us" />
                <h3>{d.title}</h3>
            </div>
        ))}
      </div>
    </div>
  )
}
