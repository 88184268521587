import './Menu.scss'
import { Link } from 'react-router-dom'

export default function Menu({menuOpen,setMenuOpen}) {
  return (
    <div className={'menu ' + (menuOpen && 'active')}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
            <a href="/#intro">Home</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
            <a href="https://courses.mapcomacademy.com/">Courses</a>
        </li>
        {/* <li onClick={()=>setMenuOpen(false)}>
            <a href="#works">Works</a>
        </li> */}
        <li onClick={()=>setMenuOpen(false)}>
            <a href="/#aboutus">About Us</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
            <a href="/#contact">Contact</a>
        </li>
        <hr />
        <li className='tli'>
            <ul className='terms'>
              <li onClick={()=>setMenuOpen(false)}>
              <Link to="/terms">Terms and Conditions</Link>
              </li>
              <li onClick={()=>setMenuOpen(false)}>
              <Link to="/privacy" >Privacy Policy</Link>
              </li>
              <li onClick={()=>setMenuOpen(false)}>
              <Link to="/refunds">Refund policy</Link>
              </li>
            </ul>

        </li>

        <hr />
        
      </ul>
      <div className="itemcontainer">
        <div ><a href="https://api.whatsapp.com/send?phone=7678231134" target="_blank" className="phone" rel="noreferrer">+91-7678231134 </a></div>
        <div ><a href = "mailto: info@mapcomacademy.com" className="mail">info@mapcomacademy.com</a></div>
      </div>
    </div>
  )
}
