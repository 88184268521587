// import { useState } from "react";
import "./Contact.scss"
import { Person, Mail } from "@mui/icons-material"

export default function Contact() {
  // const [message, setMessage] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   document.getElementById("myform").reset();
  //   setMessage(true);
  // };

  const data = [
    {
      "id": 1,
      "icon": "assets/contact/pin.png",
      "title": "Our Office Address",
      "info": "A5 , 1st floor Milap Nagar Uttam Nagar East , New Delhi 110059, Uttam Nagar, Delhi 110059"
    },
    {
      "id": 2,
      "icon": "assets/contact/email.png",
      "title": "General Enquiries",
      "info": "info@mapcomacademy.com"
    },
    {
      "id": 3,
      "icon": "assets/contact/telephone.png",
      "title": "Call Us",
      "info": "7678231134 , 8750910269"
    },
    {
      "id": 4,
      "icon": "assets/contact/pin.png",
      "title": "Our Timing",
      "info": "Mon - Sat : 09:00 AM - 06:00 PM |          Sun : Closed"
    },
  ]

  return (
    <div className="contact" id="contact">
      <div className="left">
        <div className="itemcontainer">
          <Person className="icon" />
          <span > <a href="https://api.whatsapp.com/send?phone=7678231134" target="_blank" className="phone" rel="noreferrer">+91-7678231134 </a></span>
          <Mail className="icon" />
          <span ><a href = "mailto: info@mapcomacademy.com" className="mail">info@mapcomacademy.com</a></span>
        </div>
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        {data.map((d) => (
          <div className="box">
            <div className="rleft">
              <img src={d.icon} alt="Contact icon" />
            </div>
            <div className="right">
              <div className="top">{d.title}</div>
              <div className="bottom">{d.info}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
