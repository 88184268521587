import"./RefundPolicy.scss"
import { Link } from 'react-router-dom'

export default function RefundPolicy() {
  return (
    <div className="refunds">
      
      <div className="content">
      <div className="left">
        <img src="./assets/refund.webp" alt="Refund policy" />
      </div>
      <div className="right">
      <h1>Refund Policy</h1>
      <p>
      we offer users a three (3)- day , no-question-asked money back guarantee on all courses.
       <br /> The refund should be claimed within 3 days of registration, no such request will be entertained after 3 days.
       <br />The 3-day, no question refund policy stands null in following scenarios:
       <ul>
        <li>1. any kind of content is downloaded from Mapcom Academy learning management system.</li>
        <li>2. if you watch more than 2 class recordings from Mapcom Academy learning management system.</li>
        <li>3. if we believe that you are abusing our refund policy, it will be our sole discretion to suspend or terminate your account and refuse or restrict any and all current or future use of company products, without any liability to you.</li>
        <li>4. company reserves the absolute rights to revise these terms without prior notice to you other than by posting revised terms on the website.</li>
        <li>5. To request a refund, please raise a support ticket from your Mapcom Academy support .</li>
       </ul>

       </p>
      </div>
      </div>
      <div className="buttons">
          <Link to="/">Home</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>
    </div>
  )
}
