import "./Topbar.scss"
import { Person } from "@mui/icons-material"
export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">Mapcom Academy
          {/* <div className="ac">Academy</div> */}
          </a>
          <div className="itemcontainer">
            <Person className="icon" />
            <span>+91-7678231134</span>
          </div>

        </div>
        <div className="right">
          <div className="itemcontainer">

            <div className="navList">
              <ul>
                <li onClick={() => setMenuOpen(false)}>
                  <a href="#intro">Home</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                  <a href="https://courses.mapcomacademy.com/">Courses</a>
                </li>
                {/* <li onClick={() => setMenuOpen(false)}>
                  <a href="#works">Works</a>
                </li> */}
                <li onClick={() => setMenuOpen(false)}>
                  <a href="#aboutus">About Us</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    </div>
  )
}