import"./PrivacyPolicy.scss"
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
  return (
    <div className="privacy">
         <h1>Privacy Policy</h1>
         <ol>
            <li>
                <h3>Privacy :</h3>
                <p>any personal information submitted in connection with your use of the site is subject to our privacy policy which is available at https://www.mapcomacademy.com privacy-policy the terms of which are hereby incorporated into these terms by reference. please review our privacy policy to understand our practices.</p>
            </li>
            <li>
                <h3>Service two :</h3>
                <p>the user may obtain certain confidential information, including without limitation, technical, contractual, product, pricing and other valuable information that should reasonably be understood as confidential (“confidential information”). the user acknowledges and agrees to hold all confidential information in strict confidence. title and all interests to all confidential information shall be vested in the company. the user’s obligations regarding confidential information will survive the termination of these terms of use in accordance with the clause on ‘termination’ below. the user agrees that its obligations under this clause is necessary and reasonable in order to protect the company’s business and expressly agrees that monetary damages would be inadequate to compensate for any breach of any covenant or agreement set forth herein. accordingly, the user agrees and acknowledges that any such violation or threatened violation will cause irreparable harm and injury to the company and that, in addition to any other remedies that may be available, in law, equity or otherwise, the company shall be entitled to obtain injunctive relief against the threatened breach of these terms or the continuation of any such breach.</p>
            </li>
            <li>
                <h3>General :</h3>
                <p>the company products enable the users to learn via live and recorded instruction, tutoring, and learning services through our proprietary software. the services include, without limitation, facilitating and hosting courses, and taking feedback from users.
                    you understand and agree that these terms are agreed to in consideration of your use of the company products and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged.
                    changes to these terms. company reserves the absolute right to revise these terms in its sole discretion at any time and without prior notice to you other than by posting the revised terms on the site. any revisions to the terms are effective upon posting. the terms will be identified as of the most recent date of revision. you should visit this page regularly to ensure your continued acceptance of these terms. your continued use of the company products after any revision to these terms constitutes your binding acceptance of the revised terms. notwithstanding the preceding sentences of this section, no revisions to these terms will apply to any dispute between you and the company that arose prior to the date of such revision.</p>
            </li>
            <li>
                <h3>fees and taxes :</h3>
                <p>Accessing the site and browsing courses is free of cost. company however reserves the right to change its fee policies at any time in its sole discretion, including charging for access to the site, but no fee change will be binding upon you until you agree to such fee changes.
                    unless otherwise stated, all fees are quoted in united states dollars. you are responsible for paying all fees and applicable taxes associated with the site in a timely manner with a valid payment method that is accepted by the company in its sole discretion. if your payment method fails or your account is past due, then we may collect fees owed using other collection mechanisms. this may include charging other payment methods on file with us and/or retaining collection agencies and legal counsel. we may also block your access to any company products pending resolution of any amounts due by you to company.</p>
            </li>
            <li>
                <h3>general disclaimer :</h3>
                <p>we have no mechanism to control comments/discussions posted on the site and, as such, we cannot guarantee in any manner the reliability, validity, accuracy or truthfulness of such contents. you also understand that by using the site you may be exposed to submitted contents which you may consider offensive, indecent, or objectionable. you hereby agree to indemnify and hold the company harmless from and against any and all claims, notices and actions that you may have arising out of your access or use of any submitted content.</p>
            </li>
            <li>
                <h3>As a user, you agree that :</h3>
                <p>1.1. you have read, understood, and agree to be bound by the pricing information (see the pricing section below) before using the site or registering for a course.
                    <br /> 1.2. if you are under the age of 18 (eighteen), you have obtained parental or legal guardian consent before using the site, or registering for a course;
                    <br />1.3 you also agree that you will not do any of the following on or through the company products; upload, post or otherwise transmit any unsolicited or unauthorised advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes or any other form of solicitation (commercial or otherwise);
                    <br />1.4 you may not transfer your account to any other person and you may not use anyone else's account at any time. in cases where you have authorized or registered another individual, including a minor, to use your account, you are fully responsible for (i) the online conduct of such user; (ii) controlling the user's access to and use of the services; and (iii) the consequences of any misuse.</p>
            </li>
         </ol>
        <div className="buttons">
          <Link to="/">Home</Link>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/refunds">Refund Policy</Link>
        </div>
    </div>
  )
}
