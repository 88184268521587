import Topbar from "./components/topbar/Topbar"
import Menu from "./components/menu/Menu"
import Sections from "./Sections/Sections"
// import Works from "./components/works/Works"
import TermsCondition from "./components/terms/TermsCondition"
import PrivacyPolicy from "./components/terms/PrivacyPolicy"
import RefundPolicy from "./components/terms/RefundPolicy"


import { useState } from "react"


import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'


function App() {
  const [menuOpen,setMenuOpen] = useState(false)
  return (
    <Router>
      <div className="app">
      <Topbar menuOpen = {menuOpen} setMenuOpen ={setMenuOpen}/>
      <Menu menuOpen = {menuOpen} setMenuOpen ={setMenuOpen}/>
      
      </div>

    <Routes>
      <Route exact path="/" element={<Sections/>}/>
      <Route path="/terms" element={<TermsCondition/>}/>
      <Route path="/privacy" element={<PrivacyPolicy/>}/>
      <Route path="/refunds" element={<RefundPolicy/>}/>
    </Routes>
    </Router>
  );
}

export default App;
