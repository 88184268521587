import "./Intro.scss"
import Typewriter from 'typewriter-effect';

export default function Intro() {
  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgcontainer">
          <img src="assets/student.png" alt="intro_img" />
        </div>
      </div>
      <div className="right">
        <div className="cert">
          <img src="assets/cert/iso.png" alt="iso certified" className="iso" />
          <img src="assets/cert/msme.png" alt="msme certified" className="msme" />
        </div>
        <div className="wrapper">
          <h2>Hi there, we are</h2>
          <h1>Mapcom Academy</h1>
          <h3> <span><Typewriter

            options={{
              strings: ["Inovating Skills", "Developing Career", "Improving personality"],
              autoStart: true,
              delay: 75,
              deleteSpeed: 10,
              loop: true
            }}

          /> </span></h3>
          <a href="https://api.whatsapp.com/send?phone=7678231134" target="_blank" class="button" rel="noreferrer">Chat now for more details</a>
        </div>
        <a href="#whyus" className="downArrow">
          <img src="assets/down.png" alt="down_arrow" />
        </a>
      </div>
    </div>
  )
}
