import "./Testimonial.scss"

export default function Testimonial() {
  const data = [
    {
      id: 1,
      name: "shanti sharma",
      title: "Mapcom Graduate",
      img:
        "assets/user.png",
      icon: "assets/twitter.png",
      desc:
        "Great place for learning , faculties and counsellor are very humble and have good teaching style . Best institute data science course",
    },
    {
      id: 2,
      name: "Hrishav Singh Rajput",
      title: "Mapcom Graduate",
      img:
        "assets/user.png",
      icon: "assets/youtube.png",
      desc:
        "Best in the sector, affordable, friendly environment.",
      featured: true,
    },
    {
      id: 3,
      name: "Jasmine kue",
      title: "Mapcom Graduate",
      img:
      "assets/user.png",
      icon: "assets/linkedin.png",
      desc:
        "Best things in Mapcom Academy is their personalised approach to teaching. Their instructors take the time to get to know each student, understand their strengths, weaknesses to develop customised lesson plans",
    },
  ];
  return (
    <div className="testimonial" id="testimonial">
      <h1>Reviews</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              {/* <img src="assets/right-arrow.png" alt="right-arrow" className="left" /> */}
              <img className="user" src={d.img} alt="img" />
              {/* <img className="right" src={d.icon} alt="youtube" /> */}
            </div>
            <div className="center">
             {d.desc}
            </div>
            <div className="bottom">
              <h3>{d.name}</h3>
              <h4>{d.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
