import "./AboutUs.scss"

export default function AboutUs() {
  return (
    <div className='aboutus' id="aboutus">
      <h1>About Us</h1>
      <img src="assets/aboutus.webp" alt="About Us" />
      <div className="info">
        We, Mapcom Academy, situated at Uttam Nagar and Rajouri Garden, Delhi, are a leading institute offering a wide range of computer courses for all. Our mission is quality training with an affordable fee structure. Our uniquely designed curriculum makes our students employable and future ready. We have a dedicated team of skilled teachers and counsellors who make sure that regular up-gradation of courses is provided to students along with career guidance. Our professional team works harmoniously in unison, pushing the frontiers in growth and quality in imparting education.
      </div>
    </div>
  )
}
